import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ReviewRow from "../components/ReviewRow"
import CloseIcon from "../components/CloseIcon"
import CTA from "../components/CTA/CTA"
import GetStarted from "../components/CTA/GetStarted"
import HeadingParagraphButton from "../components/CTA/HeadingParagraphButton"

import { Dialog, setHotkeysDialogProps } from "@blueprintjs/core"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { instructions } from "../cms/collections/instructions"
import stars from "../images/stars.svg"

var classNames = require("classnames")

class AnesthesiaOptionsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogIsOpen: false,
      componentShownInDialog: {}
    }
    this.toggleDialog = this.toggleDialog.bind(this)
  }

  componentDidUpdate(prevState) {
    if (
      this.state.dialogIsOpen !== prevState.dialogIsOpen &&
      this.state.dialogIsOpen
    ) {
      document.documentElement.style.overflowY = "hidden"
    } else {
      document.documentElement.style.overflowY = "scroll"
    }
  }

  toggleDialog(component) {
    this.setState(prevState => ({
      dialogIsOpen: !prevState.dialogIsOpen,
      componentShownInDialog: component
    }))
  }

  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]

    return (
      <SharedStateProvider>
        <Layout pageTitle="an-options">
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            pathname={this.props.location.pathname}
            schemaPage={post.schemaPage ? post.schemaPage : null}
          />

          <Dialog
            canOutsideClickClose={true}
            enforceFocus={true}
            autoFocus={true}
            canEscapeKeyClose={true}
            isOpen={this.state.dialogIsOpen}
            usePortal={true}
            onClose={this.toggleDialog}
            className="anesthesia-dialog"
          >
            {this.state.componentShownInDialog}
          </Dialog>
          <div>
            <Button buttonText="BACK" goBack />
            <div className="procedure top-section white-back">
              <div className="columns">
                <div className="column is-5"></div>
                <div className="column">
                  <h1 style={{ textAlign: "center" }}>{post.heading}</h1>
                  <div
                    dangerouslySetInnerHTML={createHtml(
                      converter.makeHtml(post.topBlurbs)
                    )}
                  ></div>
                </div>
                <div className="column is-4"></div>
              </div>
            </div>
          </div>

          <div
            className="columns has-text-centered color-back anesthesia-modal"
            style={{ paddingTop: "32px", paddingBottom: "50px" }}
          >
            <div className="column is-5"></div>
            <div className="column">
              <h3>{post.anesthesiaTypes.heading}</h3>
              <div
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(post.anesthesiaTypes.blurb)
                )}
              ></div>
              <div className="button-group-wrapper">
                <div className="button-group is-centered has-3-button">
                  <a
                    className="standard-button contained"
                    onClick={() =>
                      this.toggleDialog(
                        <AnesthesiaModal
                          modalHeading={
                            post.anesthesiaTypes.buttonOne.modalHeading
                          }
                          modalBlurb={post.anesthesiaTypes.buttonOne.modalBlurb}
                          youtube={post.anesthesiaTypes.buttonOne.youtube}
                          toggleDialog={this.toggleDialog}
                        />
                      )
                    }
                  >
                    {post.anesthesiaTypes.buttonOne.buttonLabel}
                  </a>
                  {/* <a
                    className="standard-button contained"
                    onClick={() =>
                      this.toggleDialog(
                        <AnesthesiaModal 
                        modalHeading={post.anesthesiaTypes.buttonTwo.modalHeading}
                        modalBlurb={post.anesthesiaTypes.buttonTwo.modalBlurb}
                        youtube={post.anesthesiaTypes.buttonTwo.youtube}
                        toggleDialog={this.toggleDialog} />
                      )
                    }
                  >
                    {post.anesthesiaTypes.buttonTwo.buttonLabel}
                  </a> */}
                  <a
                    className="standard-button contained"
                    onClick={() =>
                      this.toggleDialog(
                        <AnesthesiaModal
                          modalHeading={
                            post.anesthesiaTypes.buttonThree.modalHeading
                          }
                          modalBlurb={
                            post.anesthesiaTypes.buttonThree.modalBlurb
                          }
                          youtube={post.anesthesiaTypes.buttonThree.youtube}
                          toggleDialog={this.toggleDialog}
                        />
                      )
                    }
                  >
                    {post.anesthesiaTypes.buttonThree.buttonLabel}
                  </a>
                </div>
              </div>
            </div>
            <div className="column is-5"></div>
          </div>

          {post.youtube && post.imageId && (
            <div className="body-sections anesthesia-watch-video">
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                controls={true}
                playing={true}
                overVideo={true}
                buttonClass="contained"
              >
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={post.imageId}
                  width="auto"
                ></ImageMeta>
              </TheaterVideo>
            </div>
          )}

          <div
            className="body-sections anesthesia-body-section"
            style={{ paddingBottom: "0" }}
          >
            <div className="columns">
              <div className="column is-5"></div>
              <div
                className="column"
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(post.body)
                )}
              ></div>
              <div className="column is-5"></div>
            </div>
          </div>

          <GetStarted
            className="get-started-cta has-text-centered"
            centerHeading
            headingSideColumnIs={5}
            sideColumnIs={4}
            subheading={post.getStarted.subheading}
            heading={post.getStarted.heading}
            paragraph={
              <div
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(post.getStarted.blurb)
                )}
              ></div>
            }
            buttonText={post.getStarted.buttonText}
            buttonUrl={post.getStarted.href}
          />
        </Layout>
      </SharedStateProvider>
    )
  }
}

function AnesthesiaModal(props) {
  var modalClass = classNames({
    "c-menu": true,
    "dialog-one": true
  })

  return (
    <div className="has-text-centered dialog-content">
      <CloseIcon onClick={props.toggleDialog} />
      <h3>{props.modalHeading}</h3>
      <p className="large">{props.modalBlurb}</p>
      {props.youtube && (
        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${props.youtube}`}
          controls={true}
          playing={true}
          onlyButton={true}
          buttonClass="contained"
        />
      )}
      {!props.youtube && <div style={{ height: "80px" }}></div>}
    </div>
  )
}

export const pageQuery = graphql`
  query anesthesiaOptionsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        schemaPage
        anesthesiaTypes {
          blurb
          buttonOne {
            buttonLabel
            modalBlurb
            modalHeading
            youtube
          }
          buttonThree {
            buttonLabel
            modalBlurb
            modalHeading
            youtube
          }
          heading
          buttonTwo {
            buttonLabel
            modalBlurb
            modalHeading
            youtube
          }
        }
        youtube
        imageId
        body
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
        }
        heading
        title
        topBlurbs
      }
    }
  }
`

export default AnesthesiaOptionsPage
